<template>
  <div class="member-index">
    <el-tabs type="card" v-model="searchForm.type" @tab-click="handleTabChange">
      <el-tab-pane label="系统消息" name="message">
        <template v-if="total">
          <el-collapse accordion>
            <el-collapse-item
              v-for="item in list"
              :key="item.id"
              @click.native="handleRead(item)"
            >
              <template slot="title">
                <div class="title-wrapper">
                  <div class="left">
                    <el-avatar
                      src="https://www.om.cn/_nuxt/img/message_system.png?7f2503c"
                    ></el-avatar>
                    <div
                      class="left-title"
                      :class="{ unread: item.IsRead === 0 }"
                    >
                      <span>{{ item.title }}</span>
                      <span>{{ item.desc }}</span>
                    </div>
                  </div>
                  <div class="right">
                    <span>{{ item.createTime }}</span>
                    <el-popconfirm
                      title="确定删除此消息吗？"
                      @confirm="handleDel(item.id)"
                    >
                      <i
                        class="el-icon-delete"
                        @click.stop
                        slot="reference"
                      ></i>
                    </el-popconfirm>
                  </div>
                </div>
                <!-- 一致性 Consistency
              <i class="header-icon el-icon-info"></i>-->
              </template>
              <div>{{ item.content }}</div>
            </el-collapse-item>
          </el-collapse>
          <el-pagination
            :page-size="searchForm.pagenum"
            :current-page.sync="searchForm.page"
            :total="total"
            @current-change="handleCurrentChange"
            hide-on-single-page
          ></el-pagination
        ></template>
        <el-empty v-else description="空空如也~"></el-empty>
      </el-tab-pane>
      <!-- <el-tab-pane label="官方" name="active">
        <el-empty description="空空如也~"></el-empty>
      </el-tab-pane> -->
    </el-tabs>
    <div class="fixed-operate">
      <el-button
        type="text"
        :disabled="!total"
        style="color: '#666'"
        @click="readAll"
        >全部已读</el-button
      >
      <el-button
        type="text"
        :disabled="!total"
        style="color: '#666'"
        @click="delAll"
        >全部删除</el-button
      >
    </div>
  </div>
</template>

<script>
import {
  delAllMessage,
  delMessage,
  editMessage,
  getMessageList,
  readAllMessage,
} from "../../api";
export default {
  data() {
    return {
      list: [],
      total: 0,
      searchForm: {
        type: "message",
        page: 1,
        pagenum: 10,
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.$store.dispatch("getMessage");
      const res = await getMessageList({
        ...this.searchForm,
        type: this.type,
      });
      this.list = res.data;
      this.total = res.count;
    },
    handleTabChange(tab) {
      this.type = tab.name;
      this.list = [];
      this.total = 0;
      this.searchForm = {
        page: 1,
        pagenum: 10,
      };
      this.getData();
    },
    handleCurrentChange(page) {
      this.searchForm.page = page;
      this.getData();
    },
    handleRead(item) {
      if (item.IsRead) return;

      item.IsRead = 1;
      this.$nextTick(async () => {
        await editMessage({
          id: item.id,
          handleAll: false,
        });
        this.$store.dispatch("getMessage");
      });
    },
    async handleDel(id) {
      await delMessage({
        id: id,
        handleAll: false,
      });
      this.$message.success("删除成功");
      this.getData();
    },
    async readAll() {
      await readAllMessage({
        id: null,
        handleAll: true,
      });
      this.$message.success("操作成功");
      this.getData();
    },
    delAll() {
      this.$confirm("确定要删除所有消息吗?", "提示", {}).then(async () => {
        await delAllMessage({
          handleAll: true,
        });
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.searchForm.page = 1;
        this.getData();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.member-index {
  padding: 10px 20px;
  background: #fff;
  position: relative;
  .fixed-operate {
    position: absolute;
    right: 20px;
    top: 13px;
    .el-button {
      margin-left: 10px;
      color: #666;
    }
  }
  ::v-deep {
    .el-form-item--small.el-form-item {
      margin-bottom: 9px;
    }
    .el-radio__input {
      display: none;
    }
  }
  .custom-select {
    width: 120px;
    margin: 0 6px;
  }
  ::v-deep.el-collapse-item__header {
    height: 60px;
    line-height: auto;
  }
  .title-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      align-items: center;
      .left-title {
        margin-left: 15px;
        display: flex;
        flex-direction: column;
        line-height: 22px;
        position: relative;
        &.unread {
          &::before {
            content: "";
            width: 6px;
            height: 6px;
            left: -10px;
            top: 5px;
            background: #f13737;
            border-radius: 50%;
            display: block;
            position: absolute;
          }
        }
        span {
          &:first-child {
            font-size: 16px;
          }
          &:last-child {
            color: #777;
          }
        }
      }
    }
    .right {
      margin-right: 5px;
      display: flex;
      flex-direction: column;
      span {
        line-height: 22px;
        text-align: right;
      }
      i {
        font-size: 16px;
        align-self: flex-end;
      }
    }
  }
  ::v-deep {
    .el-pagination {
      text-align: right;
      padding: 10px 0;
    }
  }
}
</style>
